/* eslint-disable */

import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

function AboutPage({ data }) {
  return (
    <Layout>
      <SEO
        keywords={[`About Angela Dechaine`, `Wellspring BCST`, `Wellspring Cranio`, `Wellspring Biodynamic Craniosacral Therapy`]}
        title="About Angela Dechaine"
      />

      <div className="px-8 md:px-16 lg:max-w-screen-lg mx-auto">

        <h1 className="my-8 md:my-12 lg:my-16 font-semibold text-lg md:text-2xl lg:text-3xl text-gray-800 tracking-wider text-center">
          Angela Dechaine, BCST
        </h1>

        <Img
          className="my-12 mx-4"
          fluid={data.mainImage.childImageSharp.fluid}
          alt="Angela Dechaine lakefront"
        />

        <div className="mx-6 md:mx-24 my-12">
          <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify border-l-4 border-gray-900">
            The sun shines not on us but in us. The rivers flow not past, but through us. Thrilling, tingling, vibrating every fiber and cell of the substance of our bodies, making them glide and sing.
          </blockquote>
          <cite className="block mt-4 text-xs font-bold text-right uppercase">
            - John Muir
          </cite>
        </div>

        {/*
        <div className="md:flex mx-4">
          <div className="">
            <Img
              className="my-4"
              fixed={data.profileImage.childImageSharp.fixed}
              alt="Angela"
            />
          </div>

          <div className="ml-4">
            <h4 className="mt-2 text-lg md:text-xl font-bold tracking-wide text-blue-800">
              Angela Dechaine, BCST (Body Intelligence Training 2017)
            </h4>

            <p className="font-bold mt-4">
              Biodynamic Craniosacral Therapist
            </p>

            <p className="font-bold">
              Body Intelligence Training (bodyintelligence.com)
            </p>

            <p className="font-bold">
              Yoga and Ecstatic Dance Instructor
            </p>

            <p className="font-bold">
              BSc Psychology
            </p>
          </div>
        </div>
        */}

        <div className="mt-6 mx-4">
          <div className="hidden md:block">
            <Img
              className="mt-2 md:mx-4 md:ml-0 md:float-left"
              fixed={data.profileImage.childImageSharp.fixed}
              alt="Angela Dechaine"
            />
          </div>

          <div className="">
            <p className="mt-4">
              Angela believes that we are meant to live a life of deep feeling and connection; to fully  inhabit our miraculous bodies and contemplate who we are. The foundational principles  of Biodynamic bodywork inspire her on many levels, both personally and professionally.
            </p>

            <p className="mt-4">
              “I love nature. It is more than a love for nature, it gives me a sense of home and  belonging, it gives me a sense of Self. Nature reminds me who I am, where I come  from, and how significant my Life is. My body has rhythms and seasons, it ebbs and  flows, it expands and retreats; it has this remarkable intelligence that I deeply trust and  revere.”
            </p>

            <p className="mt-4">
              This unwavering trust in an internal wisdom has been initiated by time immersed in  nature, working with animals, practicing and teaching yoga, exploring meditation, and  moving her body in conscious dance.
            </p>

            <p className="mt-4">

            </p>When diagnosed with cancer in 2013, she knew that she could trust the intelligence of  her body to guide her through the choices she would have to make in order to heal.  Most importantly, she knew that she could focus on healing her body vs. fighting to  survive.

            <p className="mt-4">
              Biodynamic Craniosacral Therapy (BCST) became a significant part of her post cancer  treatment recovery. It made a profound impact on regulating her nervous system and  reconnecting her to a sense of health. So much so that she felt called to become a  practitioner.
            </p>

            {/* <p className="mt-4"> */}
            {/*   Angela completed her BSCT training with Body Intelligence in 2017 and she recently  joined the Body Intelligence teaching team as an assistant. She aspires to teach this  remarkable work that she is so passionate about. Her favorite part about BCST is the  experience of being deeply listened to on all levels and the natural relief that follows.  She is especially interested  in body-oriented trauma resolution, supporting those that are processing trauma by  providing a gentle and safe avenue to release shock from the body and regulate the nervous system. Most of all, she loves supporting others to connect to the health in their  system, no matter the ailment. */}
            {/* </p> */}
            <p className="mt-4">
              Angela completed her BSCT training with Body Intelligence in 2017 and she recently joined the Body Intelligence teaching team as an assistant. Her favourite part of the practice is deeply listening to another being on all levels and supporting them to connect to the Health in their system, no matter the ailment. She is constantly inspired by the power of nervous system regulation and trauma resolution to support healing and wellness.
            </p>
          </div>

          <div className="md:hidden">
            <Img
              className="mt-6"
              fixed={data.profileImage.childImageSharp.fixed}
              alt="Angela Dechaine"
            />
          </div>
        </div>

        <hr className="w-4/6 my-16 mx-auto" />

{/*         <h2 className="mt-8 mb-4 md:mt-12 md:mb-6 lg:mt-16 lg:mb-8 font-semibold text-lg md:text-2xl lg:text-3xl text-gray-800 tracking-wider"> */}
{/*           Testimonials */}
{/*         </h2> */}
{/*  */}
{/*         <div className="mx-6 md:mx-24 my-12"> */}
{/*           <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify"> */}
{/*             “Cranio with Angela has allowed me to remain grounded through unthinkable times over the past year. Not only do her treatments leave me feeling revitalized and refreshed; I leave feeling empowered and strong knowing that my body has every intention of carrying me through. Words cannot express how grateful I am for her talent and grace &mdash; she&apos;s stuck with me for life!” */}
{/*           </blockquote> */}
{/*           <cite className="block mt-2 text-xs font-bold text-right uppercase"> */}
{/*             - Ashley */}
{/*           </cite> */}
{/*         </div> */}
{/*  */}
{/*         <div className="mx-6 md:mx-24 my-12"> */}
{/*           <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify"> */}
{/*             “I want to highly recommend Angela and her work. I was struggling with grieving the loss of my father, and I was unable to sleep for days. Angela created space and support for me and my healing. The same night as my treatment, I was able to release and sleep for 7 hours. I have continued to progress, and feel the effects of the treatment days later.” */}
{/*           </blockquote> */}
{/*           <cite className="block mt-2 text-xs font-bold text-right uppercase"> */}
{/*             - Gail */}
{/*           </cite> */}
{/*         </div> */}
{/*  */}
{/*         <div className="mx-6 md:mx-24 my-12"> */}
{/*           <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify"> */}
{/*             “My pain has been eased. I have NO PAIN walking or going down stairs, whereas before cranio I always had pain. I have more flexibility and movement in my leg now then I have had in the last year. I’m so grateful for cranio, it gave me movement again!” */}
{/*           </blockquote> */}
{/*           <cite className="block mt-2 text-xs font-bold text-right uppercase"> */}
{/*             - Myrna */}
{/*           </cite> */}
{/*         </div> */}
{/*  */}
{/*         <div className="mx-6 md:mx-24 my-12"> */}
{/*           <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify"> */}
{/*             “Angela has a gift like no other, that needs to be shouted from the highest mountain and shared abundantly! She helped me recognize many suppressed emotions and feelings that I didn’t fully know existed. These feelings were interrupting my day-to-day life, both physically and emotionally. With her gentle hands and guiding wisdom I was able to process and cultivate my heavy thoughts into healing in order to move forward and improve my personal well-being. After my first cranio session I was hooked! Thank you Angela for your compassion and trust. I look forward to or continued appointments!” */}
{/*           </blockquote> */}
{/*           <cite className="block mt-2 text-xs font-bold text-right uppercase"> */}
{/*             - Rona */}
{/*           </cite> */}
{/*         </div> */}
{/*  */}
{/*         <div className="mx-6 md:mx-24 my-12"> */}
{/*           <blockquote className="mt-8 pl-4 font-serif leading-loose text-justify"> */}
{/*             “My 13 month old son and I saw Angela today. It was such a grounding, healing experience. I felt safe and emotionally held. It was transformative for us.” */}
{/*           </blockquote> */}
{/*           <cite className="block mt-2 text-xs font-bold text-right uppercase"> */}
{/*             - Hilary */}
{/*           </cite> */}
{/*         </div> */}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    mainImage: file(relativePath: { eq: "angela-lake.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    profileImage: file(relativePath: { eq: "angela.jpg" }) {
      childImageSharp {
        fixed(height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default AboutPage;
